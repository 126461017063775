import { getStore, setStore } from '@/util/store'
export default {
  data() {
    return {
      theme: '',
      themeList: [{
        label: '默认',
        value: 'default'
      }, {
        label: '边框',
        value: 'border'
      }]
    }
  },
  created() {
    const theme = getStore({ name: 'wf-theme' })
    if (theme || theme == '') this.theme = theme || 'default'
  },
  watch: {
    theme: {
      handler(val) {
        if (val) {
          import(`../styles/theme/${val}.scss`)
        }
        setStore({ name: 'wf-theme', content: val })
      },
      immediate: true
    },
  },
}